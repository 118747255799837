<template>
  <q-btn v-if="canEdit" flat>
    <img v-if="isSmall" src="../../assets/icons/context-menu.svg" alt="" />
    <img v-else src="../../assets/icons/context-menu.svg" alt="" />
    <q-menu class="context-menu">
      <q-list>
        <q-item clickable v-close-popup @click="showEditUserDialog(user)">
          <q-icon><img src="../../assets/icons/context/edit.svg" alt=""></q-icon>
          Изменить
        </q-item>
        <q-item v-if="!user.blocked" clickable v-close-popup @click="showBlockUserDialog(user)">
          <q-icon><img src="../../assets/icons/context/block.svg" alt=""></q-icon>
          Заблокировать
        </q-item>
        <q-item v-else clickable v-close-popup @click="showActivateUserDialog(user)">
          <q-icon><img src="../../assets/icons/context/block.svg" alt=""></q-icon>
          Разблокировать
        </q-item>
        <q-item clickable v-close-popup @click="showDeleteUserDialog(user)">
          <q-icon><img src="../../assets/icons/context/delete.svg" alt=""></q-icon>
          Удалить
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import api from '../../api/api.js'
import {api as usersApi} from '../../api/users.js'
import BlockUserConfirmation from '../../dialogs/users/BlockUserDialog.vue'
import ActivateUserConfirmation from '../../dialogs/users/ActivateUserDialog.vue'
import DeleteUserConfirmation from '../../dialogs/users/DeleteUserDialog.vue'
import UserAdd from '../../dialogs/users/EditUserDialog.vue'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
    view: {
      type: String,
      default: 'menu',
    },
  },
  data() {
    return {
      canEdit: api.auth.getCurrentUser().id !== this.user.id
    }
  },
  methods: {
    showEditUserDialog(user) {
      usersApi
        .find(user.id)
        .then(user => {
          this.$root.createDialog({
            component: UserAdd,
            componentProps: {
              user: user,
              hook: this.hook,
            }
          }).onOk(user => {
            this.$root.showSuccessMessage(`Пользователь ${user.name} обновлён.`)
          })
        })
    },
    showBlockUserDialog(user) {
      this.$root.createDialog({
        component: BlockUserConfirmation,
        componentProps: {
          user: user,
          hook: this.hook,
        },
      }).onOk(user => {
        this.$root.showSuccessMessage(`Пользователь ${user.name} заблокирован.`)
      })
    },
    showActivateUserDialog(user) {
      this.$root.createDialog({
        component: ActivateUserConfirmation,
        componentProps: {
          user: user,
          hook: this.hook,
        },
      }).onOk(user => {
        this.$root.showSuccessMessage(`Пользователь ${user.name} разблокирован.`)
      })
    },
    showDeleteUserDialog(user) {
      this.$root.createDialog({
        component: DeleteUserConfirmation,
        componentProps: {
          user: user,
          hook: this.hook,
        },
      }).onOk(user => {
        this.$root.showSuccessMessage(`Пользователь ${user.name} удалён.`)
      })
    },
  },
  computed: {
    isSmall() {
      return this.$q.screen.width < 1000
    }
  }
}
</script>
