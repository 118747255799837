<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/block.svg" class="dialog-header-icon" alt="">
        Блокировка пользователя
      </div>

      <p>
        Вы действительно хотите заблокировать пользователя {{ user.name }}?
      </p>
      <p>
        После блокировки пользователю будет заблокрован
        доступ в систему, вы всегда сможете восстановить доступ обратно.
      </p>

      <div class="dialog-actions">
        <q-btn label="Отменить" flat class="cancel-button" @click="onCancelClick" />
        <q-btn label="Блокировать" color="primary" @click="onOKClick" :loading="processing"/>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import {api as usersApi} from "../../api/users.js";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
    }
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    onOKClick () {
      this.processing = true
      usersApi
        .block(this.user)
        .then(user => {
          this.hook(user)
            .then(() => {
              this.$emit('ok', user)
              this.hide()
              this.processing = false
            })
        })
        .catch(e => {
          console.log(e)
        })
    },
  }
}
</script>
