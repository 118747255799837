<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/add-user.svg" class="dialog-header-icon" alt="">
        {{ dialogTitle }}
      </div>

      <q-banner v-if="error" class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <q-input
          outlined
          v-model="form.name.value"
          label="Имя пользователя"
          :error="form.name.error != null"
          :error-message="form.name.error"
          maxlength="200"
          required
        />

        <q-input
          v-model="form.password.value"
          outlined
          :type="form.password.hidden ? 'password' : 'text'"
          label="Пароль"
          :error="form.password.error != null"
          :error-message="form.password.error"
          maxlength="200"
          :required="!user.id"
        >
          <template v-slot:append>
            <q-icon
              :name="form.password.hidden ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="form.password.hidden = !form.password.hidden"
            />
          </template>
        </q-input>

        <q-input
          v-if="!user.id"
          v-model="form.passwordRepeat.value"
          outlined
          :type="form.password.hidden ? 'password' : 'text'"
          label="Повторите пароль"
          :error="form.passwordRepeat.error != null"
          :error-message="form.passwordRepeat.error"
          maxlength="200"
          :required="!user.id"
        >
          <template v-slot:append>
            <q-icon
              :name="form.password.hidden ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="form.password.hidden = !form.password.hidden"
            />
          </template>
        </q-input>

        <q-input
          type="email"
          outlined
          v-model="form.email.value"
          label="Электронная почта"
          required
          :error="form.email.error != null"
          :error-message="form.email.error"
          maxlength="200"
        />

        Роль пользователя:
        <q-option-group
          :options="options"
          type="radio"
          v-model="form.role.value"
        />

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="hide" />
          <q-btn label="Сохранить" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import roles from '../../roles.js'
import { api as usersApi } from '../../api/users.js'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    hook: {
      type: Function,
      required: true,
    },
  },
  setup () {
    return {
      options: roles.loginables().map(role => {
        return { label: role.title, value: role.id }
      })
    }
  },
  data() {
    return {
      processing: false,
      error: null,

      form: {
        name: {
          value: this.user.name,
          error: null,
        },
        password: {
          value: '',
          hidden: true,
          error: null,
        },
        passwordRepeat: {
          value: '',
          error: null,
        },
        email: {
          value: this.user.email,
          error: null,
        },
        role: {
          value: this.user.role.id,
        }
      }
    }
  },
  emits: ['ok'],
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    onSubmit() {
      let hasErrors = false

      if (!this.user.id) {
        if (this.form.password.value !== this.form.passwordRepeat.value) {
          this.form.password.error = 'Пароли не совпадают.'
          this.form.passwordRepeat.error = 'Пароли не совпадают.'
          hasErrors = true
        }
      }

      if (!hasErrors) {
        const user = this.user
        user.name = this.form.name.value
        user.email = this.form.email.value
        user.role = roles.byId(this.form.role.value)

        const password = this.form.password.value

        Object.keys(this.form).forEach(key => {
          if (typeof this.form[key].error !== 'undefined') {
            this.form[key].error = null
          }
        })

        this.processing = true
        usersApi
          .save(user, password)
          .then(user => {
            this
              .hook(user)
              .then(() => {
                this.$emit('ok', user)
                this.hide()
                this.processing = false
              })
          })
          .catch(errors => {
            this.processing = false
            if (Array.isArray(errors)) {
              errors.forEach(error => {
                const title = error.title.charAt(0).toUpperCase() + error.title.slice(1)
                if (error.source) {
                  switch (error.source) {
                    case 'title':
                      this.form.name.error = title
                      break
                    case 'password':
                      this.form.password.error = title
                      break
                    case 'email':
                      this.form.email.error = title
                      break
                  }
                } else {
                  this.error = title
                }
              })
            } else {
              console.log(errors)
            }
          })
      }
    }
  },
  computed: {
    dialogTitle() {
      return this.user.id ? 'Редактирование' : 'Новый пользователь'
    }
  }
}
</script>
